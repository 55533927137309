import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>DENTAL IMPLANTS</h2>
      <h3>What are they?</h3>
      <p>Implants are one way of replacing missing teeth.  Unlike other forms of replacement teeth, dental implants are small metal or ceramic devices not unlike a screw fitting which are inserted into the jaw during surgery. Teeth, in the form of a crown, bridge or denture, are then attached to the implant.</p>
      <h3>What are the benefits?</h3>
      <p>Some people have real difficulties with removable dentures. Implants can overcome these difficulties, in particular for eating and speaking properly and they may improve appearance. If a denture is necessary, implants can greatly improve stability.  People will not be able to see that your teeth are supported by implants. Implants can be used in place of bridges, for example when adjacent teeth may not be strong enough to support a bridge or if patients have spaced teeth.</p>
      <h3>Are implants for me?</h3>
      <p>Your dentist should discuss with you whether implants would be right for you, and explain any associated risks.</p>
      <p>Patients need to have healthy gums, and enough jawbone to take the implant that supports the replacement teeth although techniques are available to add additional bone if needed. They must also be prepared to maintain good mouth hygiene and visit the dentist regularly.</p>
      <p>Implant patients need to be in good general health. Some chronic diseases, such as diabetes, osteoporosis or chronic sinus problems, could interfere with healing and make implants more likely to fail. Make sure that you tell your dentist about any medicines that you take regularly, and about your smoking habits. Smoking may well shorten the life of your implant. Implants involve surgery over a period of several months. Since they are a complicated form of treatment, implants can be expensive.</p>
      <h3>What will my dentist do?</h3>
      <p>Your dentist should tell you about  the stages of treatment, who would be carrying out each  stage and the timetable for completing treatment. You might be referred to a specialist.</p>
      <p>This is what will happen:</p>
      <p>Bone is exposed in the jaw where the tooth is missing. Then a hole is drilled and the implant is inserted into the bone. This is usually done under a local anaesthetic, but sometimes sedation or a general anaesthetic is necessary. The gum is then stitched over the implant and it's left to heal for several months. This allows bone to grow around the implant and to make it secure.</p>
      <p>A second procedure is then planned, in which replacement teeth are mounted onto the implant. This requires a small cut in the gum above the implant. Once the soft tissues have healed, the replacement teeth may be fixed permanently or attached in a way that lets you remove them for cleaning. The replacement teeth might be single or in a group, and possibly as a "bridge", attached to neighbouring natural teeth.</p>
    </LightboxLayout>
  )
}

export default Page